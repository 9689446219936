import React, { useState, useRef, useEffect } from 'react';
import './App.css';

function App() {
  const [toggleState, setToggleState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);

  // Mouse parallax effect
  const useMouseParallax = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    
    useEffect(() => {
      const handleMouseMove = (e) => {
        const x = (window.innerWidth - e.pageX * 2) / 100;
        const y = (window.innerHeight - e.pageY * 2) / 100;
        setPosition({ x, y });
      };
      
      window.addEventListener('mousemove', handleMouseMove);
      return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);
    
    return position;
  };

  // Magnetic button effect
  const useMagneticButton = () => {
    useEffect(() => {
      const buttons = document.querySelectorAll('.buttons button');
      
      const handleMouseMove = (e, button) => {
        const { left, top, width, height } = button.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;
        
        const centerX = width / 2;
        const centerY = height / 2;
        
        const deltaX = (x - centerX) / 10;
        const deltaY = (y - centerY) / 10;
        
        button.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
      };
      
      const handleMouseLeave = (button) => {
        button.style.transform = 'translate(0, 0)';
      };
      
      buttons.forEach(button => {
        button.addEventListener('mousemove', (e) => handleMouseMove(e, button));
        button.addEventListener('mouseleave', () => handleMouseLeave(button));
      });
      
      return () => {
        buttons.forEach(button => {
          button.removeEventListener('mousemove', (e) => handleMouseMove(e, button));
          button.removeEventListener('mouseleave', () => handleMouseLeave(button));
        });
      };
    }, []);
  };

  // 3D tilt effect for image
  const useImageTilt = () => {
    useEffect(() => {
      const image = document.querySelector('.image-box');
      
      const handleMouseMove = (e) => {
        const { left, top, width, height } = image.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;
        
        const multiplier = 20;
        const rotateX = multiplier * ((y - height / 2) / height);
        const rotateY = -multiplier * ((x - width / 2) / width);
        
        image.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      };
      
      const handleMouseLeave = () => {
        image.style.transform = 'rotateX(0) rotateY(0)';
      };
      
      image.addEventListener('mousemove', handleMouseMove);
      image.addEventListener('mouseleave', handleMouseLeave);
      
      return () => {
        image.removeEventListener('mousemove', handleMouseMove);
        image.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, []);
  };

  const { x, y } = useMouseParallax();

  // Initialize drops with more realistic starting positions
  const drops = useRef(Array(70).fill(0).map(() => ({
    x: Math.random() * window.innerWidth,
    y: Math.random() * -1000,
    speed: Math.random() * 2 + 2,
    length: Math.floor(Math.random() * 20) + 20
  })));

  const handleChange = () => {
    setToggleState(!toggleState);
  };

  const getRandomKatakana = () => {
    const katakana = "ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝ";
    return katakana[Math.floor(Math.random() * katakana.length)];
  };

  const drawMatrix = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.font = '25px monospace';

    drops.current.forEach((drop, i) => {
      for (let j = 0; j < drop.length; j++) {
        const char = getRandomKatakana();
        const y = drop.y - (j * 30);
        
        if (y < canvas.height && y > 0) {
          if (j === 0) {
            ctx.fillStyle = '#fff';
            ctx.font = '30px monospace';
          } else if (j < 3) {
            ctx.fillStyle = '#00ff00';
            ctx.font = '25px monospace';
          } else {
            const alpha = 1 - (j / drop.length);
            ctx.fillStyle = `rgba(0, 255, 0, ${alpha * 0.8})`;
            ctx.font = '25px monospace';
          }
          
          ctx.fillText(char, drop.x, y);
        }
      }

      drops.current[i].y += drop.speed;

      if (drop.y - (drop.length * 30) > canvas.height) {
        drops.current[i].y = -drop.length * 30;
        drops.current[i].x = Math.random() * canvas.width;
        drops.current[i].speed = Math.random() * 3 + 4;
      }
    });

    if (toggleState) {
      animationFrameRef.current = requestAnimationFrame(drawMatrix);
    }
  };

  // Loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    
    return () => clearTimeout(timer);
  }, []);

  // Audio effect
  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.muted = true;
    }
  }, []);

  useEffect(() => {
    if (toggleState) {
      audioRef.current.muted = false;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.muted = true;
    }
  }, [toggleState]);

  // Matrix animation
  useEffect(() => {
    if (toggleState) {
      const canvas = canvasRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      
      drops.current = drops.current.map(() => ({
        x: Math.random() * canvas.width,
        y: Math.random() * -1000,
        speed: Math.random() * 3 + 4,
        length: Math.floor(Math.random() * 20) + 20
      }));

      animationFrameRef.current = requestAnimationFrame(drawMatrix);
    } else {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [toggleState]);

  // Button animation effect
  useEffect(() => {
    const buttons = document.querySelectorAll('.buttons button');
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = '1';
          entry.target.style.transform = 'translateY(0)';
        }
      });
    }, { threshold: 0.1 });
    
    buttons.forEach((button, index) => {
      button.style.opacity = '0';
      button.style.transform = 'translateY(20px)';
      button.style.transitionDelay = `${index * 100}ms`;
      observer.observe(button);
    });
    
    return () => observer.disconnect();
  }, []);

  // Initialize all custom effects
  useMagneticButton();
  useImageTilt();

  return (
    <div className={`App ${toggleState ? 'dark-mode' : ''} ${isLoading ? 'loading' : ''}`}>
      <canvas ref={canvasRef} id="matrixCanvas"></canvas>
      <div className="container" style={{ position: 'relative', zIndex: 1 }}>
        <div className="left">
          <div 
            className="image-box"
            style={{
              transform: `translate(${x}px, ${y}px)`,
              transition: 'transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          >
            <img className="responsive-image" src="/IMG_7010.JPG" alt="Benjamin B" />
          </div>
          <div className="toggle-container-mobile">
            <label className="toggle-switch">
              <input type="checkbox" checked={toggleState} onChange={handleChange} />
              <span className="toggle-slider round"></span>
            </label>
          </div>
        </div>
        <div className="right">
          <div className="toggle-container">
            <label className="toggle-switch">
              <input type="checkbox" checked={toggleState} onChange={handleChange} />
              <span className="toggle-slider round"></span>
            </label>
          </div>
          <div className="right-main">
            <div className="right-content">
              <h1>Hi, My Name Is</h1>
              <h1 data-text="Benjamin Pittenger">Benjamin Pittenger</h1>
              <p>Building apps that make life easier.</p>
              <div className="buttons">
                <a href="https://www.linkedin.com/in/benjaminpittenger" target="_blank" rel="noopener noreferrer">
                  <button>LinkedIn</button>
                </a>
                <a href="https://twitter.com/ben_pittenger" target="_blank" rel="noopener noreferrer">
                  <button>Twitter</button>
                </a>
                <a href="https://orbistudios.com" target="_blank" rel="noopener noreferrer">
                  <button>ADA Website Scanner (Free)</button>
                </a>
                <a href="https://ohvii.com" target="_blank" rel="noopener noreferrer">
                  <button>Buy a Home Without an Agent (Soon)</button>
                </a>
              </div>
            </div>
          </div>
          <div className="footer">
            <p></p>
          </div>
        </div>
      </div>
      <audio ref={audioRef} loop muted>
        <source src="/price-of-freedom-33106.mp3" type="audio/mp3" />
      </audio>
    </div>
  );
}

export default App;